import React, { useRef, useState } from "react";

function Search(props) {

    const { handleSearched, handleCage, isCage, hasSearched, isLoading, setIsLoading } = props;

    const [ searchDisabled, setSearchDisabled ] = useState(true);
    
    const inputRef = useRef('');
    
    const searchForFilmTitle = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        setSearchDisabled(true);

        handleSearched(true)

        const movieTitle = inputRef.current.value;
        const response = await fetch(`https://www.omdbapi.com/?t=${movieTitle}&i=tt3896198&apikey=49a35bfb`) 
        const movies = await response.json()
        
        setIsLoading(false);
        setSearchDisabled(false);

        const actors = movies.Actors;

        if (actors && actors.includes('Nicolas Cage')) {
            handleCage(true);
        } else if (actors) {
            handleCage(false);
        } else if (movies.Error) {
            handleCage(null);
        }
    }

    const handleChange = (e) => {
        setSearchDisabled(!e.target.value);
    }
    
    return (
        <div className="form-container">
            <form className="form">
                <input type="text" ref={inputRef} placeholder="Movie title" onChange={e => handleChange(e)}></input>
                <button 
                    type="submit" 
                    disabled={searchDisabled} 
                    className="search-button" 
                    placeholder="Movie Title" 
                    onClick={searchForFilmTitle}>

                        {isLoading && (
                            <img className="loader" src={`/images/loader.png`} alt="a spinning Nicolas Cage head"/>
                        )}

                        {!isLoading && (
                            <p className="search">search</p>
                        )}

                    </button>
            </form>
        </div>
    )
}

export default Search;