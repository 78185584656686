import React from "react";

function CageImage(props) {

    const { cageImageNumber, hasSearched, isCage } = props;
    
    const getRandomBlankImageSrc = () => {
        let image = `/images/nic-cage-${cageImageNumber}.jpg`;
        return image;
    }

    const getRandomYesImageSrc = () => {
        let image = `/images/nic-cage-yes-${props.cageImageNumber}.jpg`;
        return image;
    }

    const getRandomNoImageSrc = () => {
        let image = `/images/nic-cage-no-${props.cageImageNumber}.jpg`;
        return image;
    }

    return (
        <div>
            {!hasSearched && (
                <span className="cage-face-container">
                    <img className="cage-face" src={getRandomBlankImageSrc()} alt="nicolas cage"/>
                </span>
            )}

            {hasSearched && isCage === null && (
                <span className="cage-face-container">
                    <img className="cage-face" src={getRandomBlankImageSrc()} alt="nicolas cage"/>
                </span>
            )}

            {hasSearched && isCage === false && (
                <span className="cage-face-container">
                    <img className="cage-face" src={getRandomNoImageSrc()} alt="nicolas cage frowning"/>
                </span>
            )}

            {hasSearched && isCage && (
                <span className="cage-face-container">
                    <img className="cage-face" src={getRandomYesImageSrc()} alt="nicolas cage smiling"/>
                </span>
            )}
        </div>

    );
}

export default CageImage;