import '../css/App.scss';
import React, { useState } from 'react';
import CageImage from './CageImage';
import Search from './Search';
import Title from './Title.js'
import SearchBox from './SearchBox';

function App() {
  
  const [ isCage, setIsCage ] = useState(null);
  const [ hasSearched, sethasSearched ] = useState(false);
  const [ cageImageNumber, setCageImageNumber ] = useState(3);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleCage = (cage) => {
    setIsCage(cage);
    setCageImageNumber(getCageImageNumber(cage))
  }

  const handleSearched = (search) => {
    sethasSearched(search)
  }

  const getCageImageNumber = (isCage = null) => {
    const neutralCageFaces = 13;
    const emotionalCageFaces = 7;

    // is isCage true or false? return emotional cage face number
    if (isCage !== null) {
      return Math.floor(Math.random() * emotionalCageFaces);
    }
    
    // all other possibilities, eg null isCage or state has not been defined yet, should use neutral cage face
    return Math.floor(Math.random() * neutralCageFaces);
  }

  return (
    <div className="App">
      <span className="credit">Developed by <a href="https://augustcterrell.com">August C. Terrell</a></span>

      <div className="App-header">

        <CageImage 
          isCage={isCage} 
          hasSearched={hasSearched} 
          handleSearched={handleSearched} 
          cageImageNumber={cageImageNumber}
        />

        <Title 
          isCage={isCage} 
          hasSearched={hasSearched}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />

      </div>

      <div className="App-content">

        <Search 
          isCage={isCage} 
          handleCage={handleCage} 
          hasSearched={hasSearched} 
          handleSearched={handleSearched} 
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />

        {/* <SearchBox /> */}

      </div>

    
    </div>
  );
}


export default App;